<template>
  <form @submit.prevent="closeTask">
    <modal-card
      :title="`Close task ${task.reference}`"
      :processing="processing"
    >
      <div>
        <h2 class="is-size-5 has-text-centered has-margin-bottom-200">
          Why do you want to close this task?
        </h2>
        <div class="columns">
          <div class="column is-6">
            <a @click="form.reason = 'complete'">
              <card
                :class="{ selected: form.reason === 'complete' }"
                class="has-text-centered has-cursor-pointer reason-card complete"
              >
                <template slot="body">
                  <b-icon
                    icon="check-circle"
                    size="is-medium"
                    class="has-text-success has-margin-bottom-50"
                  />
                  <h5 class="has-margin-bottom-50">
                    <strong>Task is complete</strong>
                  </h5>
                  <p>My task is complete and requires no further work.</p>
                </template>
              </card>
            </a>
          </div>
          <div class="column is-6">
            <a @click="form.reason = 'cancelled'">
              <card
                :class="{ selected: form.reason === 'cancelled' }"
                class="has-text-centered has-cursor-pointer reason-card cancelled"
              >
                <template slot="body">
                  <b-icon
                    icon="ban"
                    size="is-medium"
                    class="has-text-grey-light has-margin-bottom-50"
                  />
                  <h5 class="has-margin-bottom-50">
                    <strong>Task is redundant</strong>
                  </h5>
                  <p>Things have changed and I no longer require assistance.</p>
                </template>
              </card>
            </a>
          </div>
        </div>
      </div>

      <div
        v-if="form.reason == 'complete'"
        class="has-margin-top-200 has-margin-bottom-100 has-text-centered"
      >
        <h2 class="is-size-5 has-text-centered has-margin-bottom-150">
          Please rate how we did...
        </h2>

        <star-rating :rating="form.rating" @change="form.rating = $event" />
      </div>

      <b-collapse
        v-if="form.reason == 'complete'"
        :open="form.feedback.length > 0"
        @open="onLeaveFeedbackClick"
      >
        <p
          slot="trigger"
          class="has-text-centered has-margin-bottom-100 has-cursor-pointer"
        >
          <u>Leave feedback</u>
        </p>
        <b-input
          ref="feedback"
          v-model="form.feedback"
          type="textarea"
          rows="4"
        />
      </b-collapse>
      <button
        slot="footer"
        :class="{ 'is-loading': processing }"
        :disabled="formIsDisabled"
        type="submit"
        class="button is-success"
      >
        Close task
      </button>
    </modal-card>
  </form>
</template>

<script>
export default {
  name: "CloseTaskModal",
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      processing: false,
      form: {
        reason: "complete",
        rating: this.task.rating || 0,
        feedback: this.task.feedback || ""
      }
    };
  },
  computed: {
    formIsValid() {
      if (typeof this.form.reason === "undefined") return false;
      if (this.form.reason === "cancelled") return true;
      if (this.form.rating <= 0) return false;
      return true;
    },
    formIsDisabled() {
      return this.processing || !this.formIsValid;
    }
  },
  watch: {
    "form.reason"() {
      this.form.rating = this.task.rating || 0;
    }
  },
  methods: {
    onLeaveFeedbackClick() {
      this.$nextTick(() =>
        this.$refs.feedback.$el.querySelector("textarea").focus()
      );
    },
    closeTask() {
      if (this.formIsDisabled) return;
      this.processing = true;
      this.$store
        .dispatch("tasks/closeTask", {
          taskId: this.task._id,
          status: `0-01-${this.form.reason}`,
          rating: this.form.rating,
          feedback: this.form.feedback
        })
        .then(result => {
          this.$emit("close");
          this.$toast.open({
            message: result.message,
            position: "is-bottom",
            queue: false
          });
        })
        .catch(error => {
          this.$toast.open({
            message: error.message,
            position: "is-bottom",
            queue: false,
            type: "is-danger"
          });
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";
.reason-card {
  box-shadow: inset 0 0 0 2px $light;
  &.complete.selected,
  &.complete:hover {
    box-shadow: inset 0 0 0 2px $success;
  }
  &.cancelled.selected,
  &.cancelled:hover {
    box-shadow: inset 0 0 0 2px $grey;
  }
}
</style>
